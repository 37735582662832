import { alertController, AlertOptions, loadingController } from '@ionic/core';
import { getFunctions, httpsCallable } from '@firebase/functions';
import type {
  AnalyzeSpendingParams,
  AuthorizedUserCreateParams,
  AuthorizedUserResendEmailParams,
  AuthorizedUserUpdateParams,
  ErrorParams,
  GetAuthorizedUserParams,
  PartnerDataGetParams,
  PlaidCreateLinkTokenParams,
  GetItemParams,
  TriggerDefaultUpdateParams,
  SaveNativeSubscriptionParams,
  SendEmailParams,
  SignUpAttribution,
  StripeLaunchCheckoutParams,
  GetItemTransactionsParams,
  PlaidRefreshItemTransactionsParams,
  AsaAuthenticateParams,
  AsaLinkParams,
  IntercomIdentityVerificationParams,
  GetSignInTokenParams,
  AsaApiParams,
  ClientApiMap,
  MigrateGroupDataParams,
  AuthorizedUserConsumeParams,
} from '@og-shared/types';
import { trackAnalyticsEvent, AnalyticsEvent } from '../services/analytics';
import { environment } from './environment';
import { injectJS } from './inject-utils';
import { deepLinkInApp } from './route-in-app';
import { translatePlaceholder } from './translate';

declare const Stripe: any;

// CALLABLE FUNCTIONS

function firebaseApi<T extends keyof ClientApiMap>(params: {
  method: T;
  params: Omit<ClientApiMap[T]['params'], 'method'>;
}) {
  const combinedParams = {
    ...params.params,
    method: params.method,
  };
  return httpsCallable<any, ClientApiMap[T]['response']>(
    getFunctions(),
    'clientApi'
  )(combinedParams);
}

export async function asaAuthenticate(params: AsaAuthenticateParams) {
  const loading = await loadingController.create({});
  await loading.present();
  try {
    const res = await firebaseApi({ method: 'asaAuthenticate', params });
    const data = res.data;
    const field = data?.data?.field;
    const url = data?.data?.message;
    if (field === 'UniqueLoginUrl' && url) {
      location.href = url;
    } else {
      console.error('missing unique login Url for ASA');
    }
  } catch (err) {
    console.error('error signing in with ASA', err);
  }
  return loading.dismiss();
}

export async function getSignInTokenAndRedirect(params: GetSignInTokenParams) {
  const res = await firebaseApi({ method: 'getSignInToken', params });
  if (res.data.success) {
    const redirectUrl = res.data.data.url;
    deepLinkInApp(redirectUrl);
  } else {
    console.error('error getting sign in token');
  }
}

export async function asaLink(params: AsaLinkParams) {
  const loading = await loadingController.create({});
  await loading.present();
  try {
    await firebaseApi({ method: 'asaLink', params });
  } catch (err) {
    console.error('error linking with ASA', err);
  }
  return loading.dismiss();
}

export function firebaseLogError(params: ErrorParams) {
  return firebaseApi({
    method: 'alertError',
    params,
  });
}

export async function intercomIdentityVerification(
  params: IntercomIdentityVerificationParams
) {
  try {
    const res = await firebaseApi({
      method: 'intercomIdentityVerification',
      params,
    });
    return res.data.hash;
  } catch (err) {
    return null;
  }
}

export function firebaseAdminCreateAuthorizedUser(
  params: AuthorizedUserCreateParams
) {
  return firebaseApi({ method: 'authorizedUserCreate', params });
}

export function firebaseAdminUpdateAuthorizedUser(
  params: AuthorizedUserUpdateParams
) {
  return firebaseApi({ method: 'authorizedUserUpdate', params });
}

export async function firebaseGetCustomPartnerData(
  params: PartnerDataGetParams
) {
  const { data } = await firebaseApi({ method: 'partnerDataGet', params });
  return data;
}

export function firebaseAdminResendAuthorizedUserEmail(
  params: AuthorizedUserResendEmailParams
) {
  return firebaseApi({ method: 'authorizedUserResendEmail', params });
}

export function firebaseConsumeInvite(invite_id: string) {
  return firebaseApi({
    method: 'inviteConsume',
    params: { invite_id },
  });
}

export function firebaseGetInvite(invite_id: string) {
  return firebaseApi({
    method: 'inviteGet',
    params: { invite_id },
  });
}

export function firebasePlaidGetHoldings({ group_id }: { group_id: string }) {
  return firebaseApi({
    method: 'plaidGetHoldings',
    params: { group_id },
  });
}

export function firebaseAuthorizedUserConsume(
  params: AuthorizedUserConsumeParams
) {
  return firebaseApi({
    method: 'authorizedUserConsume',
    params,
  });
}

export function adminSendEmail(params: SendEmailParams) {
  return httpsCallable<SendEmailParams, { success: boolean }>(
    getFunctions(),
    'sendEmail'
  )(params);
}

export async function getAuthorizedUserDoc(params: GetAuthorizedUserParams) {
  const res = await firebaseApi({
    method: 'getAuthorizedUserDoc',
    params,
  });
  return res.data;
}

export async function triggerDefaultUpdate(params: TriggerDefaultUpdateParams) {
  const res = await firebaseApi({
    method: 'triggerDefaultUpdate',
    params,
  });
  return res.data;
}

export async function redeemAccessCode(group_id: string, access_code: string) {
  const res = await firebaseApi({
    method: 'accessCodeRedeem',
    params: {
      group_id,
      access_code,
    },
  });
  return res.data;
}

export async function getItemTransactions(params: GetItemTransactionsParams) {
  const res = await firebaseApi({
    method: 'getItemTransactions',
    params,
  });
  return res.data;
}

export async function asaApi(params: AsaApiParams) {
  const res = await firebaseApi({
    method: 'asaApi',
    params,
  });
  return res.data;
}

export async function plaidRefreshTransactions(
  params: PlaidRefreshItemTransactionsParams
) {
  const res = await firebaseApi({
    method: 'plaidRefreshItemTransactions',
    params,
  });
  return res.data;
}

export async function removeAllItems(group_id: string) {
  const res = await firebaseApi({
    method: 'removeAllItems',
    params: { group_id },
  });
  return res.data;
}

export async function deleteGroup(group_id: string) {
  const res = await firebaseApi({
    method: 'deleteGroup',
    params: { group_id },
  });
  return res.data;
}

export async function removeItem(group_id: string, item_id: string) {
  const res = await firebaseApi({
    method: 'removeItem',
    params: {
      group_id,
      item_id,
    },
  });
  return res.data;
}

export async function createNewUserAndGroup(params: SignUpAttribution) {
  const res = await firebaseApi({
    method: 'createNewUserAndGroup',
    params,
  });
  return res?.data?.group_id;
}

export async function stripeLaunchBillingPortal(stripe_customer_id: string) {
  const loading = await loadingController.create({});
  await loading.present();
  try {
    const res = await firebaseApi({
      method: 'stripeLaunchBillingPortal',
      params: { stripe_customer_id },
    });
    const data = res.data;
    location.href = data.url;
  } catch (err) {
    console.error('error launching billing portal', err);
  }
  await loading.dismiss();
}

export async function stripeLaunchCheckout(params: StripeLaunchCheckoutParams) {
  const loading = await loadingController.create({});
  await loading.present();
  trackAnalyticsEvent(AnalyticsEvent.stripe_launched_checkout, params);
  try {
    const res = await firebaseApi({
      method: 'stripeLaunchCheckout',
      params,
    });

    const session = res.data;
    await injectJS('stripe-elements', 'https://js.stripe.com/v3/');
    const stripe = Stripe(environment.stripeKey);
    stripe.redirectToCheckout({ sessionId: session.id });
  } catch (err) {
    console.error('error launching checkout', err);
  }
  await loading.dismiss();
}

export async function getItem(params: GetItemParams) {
  const res = await firebaseApi({
    method: 'getItem',
    params,
  });
  return res.data;
}

export async function plaidGetAndUpdateInstitutionsStatusForGroup(
  group_id: string
) {
  try {
    await httpsCallable(
      getFunctions(),
      'plaidGetAndUpdateInstitutionsStatusForGroup'
    )({
      group_id,
    });
  } catch (err) {
    console.error('error with getting plaid institution status');
  }
}

export async function plaidCreateLinkToken(params: PlaidCreateLinkTokenParams) {
  const res = await firebaseApi({
    method: 'plaidCreateLinkToken',
    params,
  });
  return res.data;
}

export async function saveNativeSubscriptionToGroup(
  params: Omit<SaveNativeSubscriptionParams, 'method'>
) {
  const res = await firebaseApi({
    method: 'saveNativeSubscriptionToGroup',
    params,
  });
  return res.data;
}

export async function analyzeSpending(params: AnalyzeSpendingParams) {
  const res = await firebaseApi({
    method: 'analyzeSpending',
    params,
  });
  return res.data;
}

export async function migrateGroup(params: MigrateGroupDataParams) {
  const loading = await loadingController.create({
    message: 'Migrating Group...',
  });
  await loading.present();
  try {
    const res = await httpsCallable<
      MigrateGroupDataParams,
      ClientApiMap['migrateGroupData']['response']
    >(
      getFunctions(),
      'migrateGroupData'
    )(params);
    const success = res?.data?.success;
    if (!success) {
      const options: AlertOptions = {
        header: translatePlaceholder('Error migrating data'),
        message: translatePlaceholder('Please try again.'),
        buttons: [
          {
            text: translatePlaceholder('Try Again'),
            handler: () => {
              migrateGroup(params);
            },
          },
        ],
      };
      const alert = await alertController.create(options);
      await alert.present();
    }
  } catch (err) {
    console.error('error migrating data', err);
  }
  return loading.dismiss();
}
